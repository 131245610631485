import type React from 'react';

type TruncateProps = {
  children: React.ReactNode;
  lines?: number | null;
};

function Truncate({ children, lines = null }: TruncateProps): React.JSX.Element {
  if (lines > 1) {
    return (
      <div className="truncate-multi-line" style={{ WebkitLineClamp: lines }}>
        {children}
      </div>
    );
  }

  if (lines === 1) {
    return <div className="truncate">{children}</div>;
  }

  return <>{children}</>;
}

export default Truncate;
